import React, {Fragment, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
const Formulario = ({crearCita}) => {
    //Crear state de citas
    const [cita, actualizarCita] = useState(
        {
            mascota: '',
            propietario: '',
            fecha: '',
            hora: '',
            sintomas: '',
        }
    )
    const [error, actualizarError] = useState(
       false
    )
    //funcion que se ejecuta cada que el usuario escribe
    const actualizarState = e => {
        actualizarCita({
            ...cita,
            [e.target.name]: e.target.value
        })
    }
    //extraer los valores
    const {mascota,propietario,fecha,hora,sintomas} = cita;
    // cuando el usuario presion enviar formulario
    const submitCita = e => {
        e.preventDefault();
        //validar
        if(mascota.trim() === '' || propietario.trim() === '' || fecha.trim() === '' || hora.trim() === '' || sintomas.trim() ===''){
            console.log("hay un error");
            actualizarError(true);
            return;
        }
        //eliminar mensaje previo
        actualizarError(false)

        //Asingar ID
        cita.id = uuidv4();
        console.log(cita)
        //crear cita
        crearCita(cita)
        //reiniciar el form
        actualizarCita({
            mascota: '',
            propietario: '',
            fecha: '',
            hora: '',
            sintomas: '',
        })
    }
    return (  
        <Fragment>

             {error ? <p className="alerta-error">Todos los campos son obligatorios</p> : null}
             <form 
                onSubmit={submitCita}
             >
                 <label>Nombre Mascota:</label>
                 <input 
                    type="text"
                    name="mascota"
                    className="u-full-width"
                    placeholder="Nombre Mascota"
                    onChange={actualizarState}
                    value= {mascota}
                />
                 <label>Nombre propietario:</label>
                 <input 
                    type="text"
                    name="propietario"
                    className="u-full-width"
                    placeholder="Nombre Dueño de la mascota"
                    onChange={actualizarState}
                    value= {propietario}
                />
                <label>Fecha:</label>
                <input 
                    type="date"
                    name="fecha"
                    className="u-full-width"
                    onChange={actualizarState}
                    value= {fecha}
                />
                <label>Hora:</label>
                <input 
                    type="time"
                    name="hora"
                    className="u-full-width"
                    onChange={actualizarState}
                    value= {hora}
                />  
                <label>Sintomas</label>   
                <textarea 
                    name="sintomas"
                    className="u-full-width"
                    onChange={actualizarState}
                    value= {sintomas}
                ></textarea>    
                <button 
                    type="submit"
                    className="u-full-width button-primary"
                >
                    Agregar cita
                </button>     
             </form>
        </Fragment>
       
    );
}
Formulario.propTypes = {
    crearCita: PropTypes.func.isRequired
}
 
export default Formulario;